import gql from 'graphql-tag';

export default gql`
    fragment SeoFragment on Seo {
        searchTitle
        metaDescription
        ogImage
        socialShareDescription
    }
`;
